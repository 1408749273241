import { OrderFulfillmentItem } from "@/interfaces/orderFulfillment";
import { orderFulfillmentService } from "@/services/orderFulfillment.service";
import { Component, Prop, Vue } from "vue-property-decorator";
import Templete from "../ModalItemDetail/ModalItemDetail.template.vue";

@Component({
  mixins: [Templete]
})
export class ModalItemDetailComponent extends Vue {
  @Prop({ required: true }) public position!: number;
  @Prop({ required: true }) public item!: OrderFulfillmentItem;

  public animateOut = "";
  public modalLoading: boolean = false;

  public async unselectModal() {
    this.animateOut = "modal__animate-out";
    setTimeout(() => this.$emit("unSelectModal"), 300);
  }

  public async successAction() {
    if (!(await this.$validator.validateAll())) {
      return;
    }
    this.modalLoading = true;
    await orderFulfillmentService.deleteUndeleteItemOrder(
      this.item.item_uid,
      "uncancel",
      { cancel_reason: this.item.cancel_reason }
    );
    this.$emit("selectModalItem", this.item);
    this.$emit("loadDetailsOrder");
  }
}
