import { OrderFulfillmentItem } from "@/interfaces/orderFulfillment";
import { orderFulfillmentService } from "@/services/orderFulfillment.service";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./CancelOrderItem.template.vue";
/**
 * Component
 */
@Component({
  mixins: [Template]
})
export class CancelOrderItemComponent extends Vue {
  @Prop({ required: true })
  public item!: OrderFulfillmentItem;
  @Prop({ required: true })
  public orderId!: string;
  @Prop({ default: false })
  public readOnly!: boolean;
  public reason?: string | null = null;
  public cancelling = false;

  /**
   * Updates : responsible for cancelling the order
   */
  public async update() {
    if (!(await this.$validator.validateAll())) {
      return;
    }
    this.cancelling = true;
    if (this.item && this.item.ordered) {
      await orderFulfillmentService.deleteUndeleteItemOrder(
        this.item.item_uid,
        "cancel",
        { cancel_reason: this.reason }
      );
    }
    this.$emit("resolve");
  }

  /**
   * Closes cancel order item component
   */
  public close() {
    this.$emit("reject");
  }

  /**
   * mounted : The mounted a native function from vuejs, responsible for filling in the data to cancel order item
   * documentation: https://vuejs.org/v2/guide/instance.html
   */
  protected async mounted() {
    this.reason = this.item.cancel_reason;
  }
}
