import { render, staticRenderFns } from "./OrderFulfillmentList.template.vue?vue&type=template&id=28ca91e4&scoped=true&"
var script = {}
import style0 from "./OrderFulfillmentList.template.vue?vue&type=style&index=0&id=28ca91e4&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ca91e4",
  null
  
)

export default component.exports