var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"section-title-font text-xs-center"},[_vm._v("\n    "+_vm._s(_vm.$t("order_fulfillment.edit_item"))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"pa-3"},[_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('div',{staticClass:"original-item"},[_c('v-layout',{staticClass:"product",attrs:{"column":""}},[_c('span',{staticClass:"text-blue font-weight-bold overline"},[_vm._v(_vm._s(_vm.$t("order_fulfillment.product")))]),_vm._v(" "),_c('span',{staticClass:"body-2 font-weight-regular mt-1"},[_vm._v(_vm._s(_vm.item.product.name))])]),_vm._v(" "),(_vm.item.ordered)?[_vm._l((_vm.item.ordered),function(batch,b){return [_c('v-layout',{key:(b + "-" + (batch.batch_uid) + "-Q"),staticClass:"qty",attrs:{"column":""}},[_c('span',{staticClass:"text-blue font-weight-bold overline"},[_vm._v(_vm._s(_vm.$t("order_fulfillment.quantity_ordered")))]),_vm._v(" "),_c('span',{staticClass:"body-2 font-weight-regular mt-1"},[_vm._v(_vm._s(batch.quantity)+_vm._s(batch.in_store_quantity_unit))])]),_vm._v(" "),_c('v-layout',{key:(b + "-" + (batch.batch_uid) + "-ID"),staticClass:"batch-id",attrs:{"column":""}},[_c('span',{staticClass:"text-blue font-weight-bold overline"},[_vm._v(_vm._s(_vm.$t("order_fulfillment.ordered_batch_id")))]),_vm._v(" "),(_vm.biotrackTrace && batch.biotrack_traceability_id)?_c('span',{staticClass:"body-2 font-weight-regular mt-1"},[_vm._v(_vm._s(batch.biotrack_traceability_id))]):_c('span',{staticClass:"body-2 font-weight-regular mt-1"},[_vm._v(_vm._s(batch.batch_uid))])]),_vm._v(" "),(
                _vm.retailSettings.stock_identification_type_in_pos ===
                  'NDC_NUMBER'
              )?_c('v-layout',{key:(b + "-" + (batch.ndc_number) + "-ID"),staticClass:"batch-id",attrs:{"column":""}},[_c('span',{staticClass:"text-blue font-weight-bold overline"},[_vm._v(_vm._s(_vm.$t("order_fulfillment.ordered_ndc_number")))]),_vm._v(" "),_c('span',{staticClass:"body-2 font-weight-regular mt-1"},[_vm._v(_vm._s(batch.product.ndc_number || "--"))])]):_vm._e(),_vm._v(" "),(
                _vm.retailSettings.stock_identification_type_in_pos ===
                  'SECONDARY_ID'
              )?_c('v-layout',{key:(b + "-" + (batch.secondary_id) + "-ID"),staticClass:"batch-id",attrs:{"column":""}},[_c('span',{staticClass:"text-blue font-weight-bold overline"},[_vm._v(_vm._s(_vm.$t("order_fulfillment.ordered_secondary_id")))]),_vm._v(" "),_c('span',{staticClass:"body-2 font-weight-regular mt-1"},[_vm._v(_vm._s(batch.tracking_id || "--"))])]):_vm._e()]})]:_vm._e()],2),_vm._v(" "),(_vm.updatingBatches)?_c('v-progress-linear',{attrs:{"height":"2","value":"15","indeterminate":"","color":"primary"}}):_c('hr',{staticClass:"hr"}),_vm._v(" "),(_vm.loadingBatches)?_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):[_vm._l((_vm.batchesRow),function(batch,i){return _c('div',{key:(i + "-ROW"),staticClass:"modified_items"},[_c('div',{staticClass:"batch-id-header"},[_vm._v("\n            "+_vm._s(_vm.$t("order_fulfillment.batch_id"))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"quantity-header"},[_vm._v("\n            "+_vm._s(_vm.$t("order_fulfillment.quantity"))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"close-header"}),_vm._v(" "),_c('div',{staticClass:"batch-id-data"},[_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"placeholder":"Select the Batch...","name":'batchUid_' + i,"required":"","error-messages":_vm.errors.collect('batchUid_' + i),"data-vv-as":"Batch ID","hide-details":"","solo":"","return-object":"","items":batch.productBatches,"suffix":batch.in_store_quantity_value + batch.in_store_quantity_unit},on:{"change":function($event){_vm.onSelectingBatch({ value: $event, selected: batch })}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"batch-selector-item"},[_vm._v("\n                  "+_vm._s(item.batch_uid)+" "),_c('br'),_vm._v(" "),(
                      _vm.retailSettings.stock_identification_type_in_pos !==
                        'NONE'
                    )?_c('small',{staticClass:"secondary_id_value"},[_vm._v("\n                    ("+_vm._s(item.secondary_id || "--")+")\n                  ")]):_vm._e()])]}},{key:"selection",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"batch-selector-item"},[_vm._v("\n                  "+_vm._s(item.batch_uid)+" "),_c('br'),_vm._v(" "),(
                      _vm.retailSettings.stock_identification_type_in_pos !==
                        'NONE'
                    )?_c('small',{staticClass:"secondary_id_value"},[_vm._v("\n                    ("+_vm._s(item.secondary_id || "--")+")\n                  ")]):_vm._e()])]}}]),model:{value:(batch.batchSelected),callback:function ($$v) {_vm.$set(batch, "batchSelected", $$v)},expression:"batch.batchSelected"}})],1),_vm._v(" "),(batch.in_store_quantity_unit === 'g')?_c('div',{staticClass:"quantity-data"},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({
                required: true,
                min_value: 0.1,
                max_value:
                  batch.in_store_quantity_value +
                  _vm.getReservedQuantity(batch.batch_uid),
                maxValueAllowed: _vm.maxValueAllowed,
                decimal: 3
              }),expression:"{\n                required: true,\n                min_value: 0.1,\n                max_value:\n                  batch.in_store_quantity_value +\n                  getReservedQuantity(batch.batch_uid),\n                maxValueAllowed,\n                decimal: 3\n              }"}],attrs:{"name":'quantity_' + i,"required":"","error-messages":_vm.errors.collect('quantity_' + i),"data-vv-as":"Quantity","solo":"","suffix":batch.in_store_quantity_unit,"type":"number","step":"0.1","min":"0"},on:{"input":_vm.onQuantityChange},model:{value:(batch.quantity),callback:function ($$v) {_vm.$set(batch, "quantity", $$v)},expression:"batch.quantity"}})],1):_c('div',{staticClass:"quantity-data"},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({
                required: true,
                min_value: 1,
                max_value:
                  batch.in_store_quantity_value +
                  _vm.getReservedQuantity(batch.batch_uid),
                maxValueAllowed: _vm.maxValueAllowed,
                integer: true
              }),expression:"{\n                required: true,\n                min_value: 1,\n                max_value:\n                  batch.in_store_quantity_value +\n                  getReservedQuantity(batch.batch_uid),\n                maxValueAllowed,\n                integer: true\n              }"}],attrs:{"name":'quantity_' + i,"required":"","error-messages":_vm.errors.collect('quantity_' + i),"data-vv-as":"Quantity","solo":"","suffix":batch.in_store_quantity_unit,"type":"number","step":"1","min":"0"},on:{"input":_vm.onQuantityChange},model:{value:(batch.quantity),callback:function ($$v) {_vm.$set(batch, "quantity", $$v)},expression:"batch.quantity"}})],1),_vm._v(" "),_c('div',{staticClass:"close-data"},[(i > 0)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.removeRow(i)}}},[_c('v-icon',[_vm._v("close")])],1):_vm._e()],1)])}),_vm._v(" "),_c('v-btn',{staticClass:"btn",attrs:{"right":"","color":"primary","flat":"","disabled":_vm.disableAdd},on:{"click":_vm.addBatch}},[_c('v-icon',{attrs:{"disabled":_vm.disableAdd,"left":"","color":"primary"}},[_vm._v("far fa-plus-circle fa-lg")]),_vm._v(" "),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t("order_fulfillment.add")))])],1)]],2)],1),_vm._v(" "),_c('hr',{staticClass:"hr"}),_vm._v(" "),_c('div',{staticClass:"text-xs-center"},[_c('v-btn',{attrs:{"disabled":_vm.disableUpdate},on:{"click":_vm.update}},[_vm._v(_vm._s(_vm.$t("order_fulfillment.update")))]),_vm._v(" "),_c('v-btn',{on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t("order_fulfillment.cancel")))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }