import { CancelOrderComponent } from "@/components/retail/orderFulfillment/cancelOrder/CancelOrder.component";
import { CancelOrderItemComponent } from "@/components/retail/orderFulfillment/cancelOrderItem/CancelOrderItem.component";
import { EditOrderItemComponent } from "@/components/retail/orderFulfillment/editOrderItem/EditOrderItem.component";
import FulfillmentLabelListComponent from "@/components/retail/orderFulfillment/tableOrderFulfillment/FulfillmentLabelList/FulfillmentLabelList.component";
import AlertComponent from "@/components/sharedComponents/alert/alert.component";
import CustomerLabelComponent from "@/components/sharedComponents/print/customerLabel/CustomerLabel.component";
import TemplateEditor from "@/components/templateEditor/editors.declaration";
import { policyList } from "@/enums/permissions";
import { Doctor } from "@/interfaces/doctor";
import {
  BatchInfo,
  CurrentScannedData,
  DetailsInScan,
  Ordered,
  OrderFulfillmentDetails,
  OrderFulfillmentFilled,
  OrderFulfillmentItem,
  OrderFulfillmentItemStatus,
  OrderFulfillmentOrdered,
  OrderFulfillmentPending,
  OrderFulfillmentStatus,
  PreOrderScann
} from "@/interfaces/orderFulfillment";
import { PharmacistInterface } from "@/interfaces/pharmacist";
import {
  defaultPrescriptionDetails,
  PrescriptionDetails
} from "@/interfaces/prescriptionDetails";
import { Product } from "@/interfaces/product";
import { RetailSettings } from "@/interfaces/retailSettings";
import { EventBus } from "@/internal";
import { customerService } from "@/services/customer.service";
import { messagesService } from "@/services/messages.service";
import { orderService } from "@/services/order.service";
import { orderFulfillmentService } from "@/services/orderFulfillment.service";
import { pharmacistService } from "@/services/pharmacist.service";
import { productService } from "@/services/product.service";
import { getParentSKU } from "@/utils/batch-actions.utils";
import { getLabsDataForPrintLabels } from "@/utils/batch-actions.utils";
import { sumObjArrayBy, truncate } from "@/utils/math.utils";
import { BooleanCheck } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import VueBarcode from "vue-barcode";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import PosPrescriptionModalComponent from "../../pos/pointOfSale/posCart/posPrescriptionModal/PosPrescriptionModal";
import { ModalItemDetailComponent } from "../ModalItemDetail/ModalItemDetail.component";
import OrderFulfillmentPrintComponent from "./orderFulfillmentPrint/orderFulfillmentPrint.component";
import Template from "./tableOrderFulfillment.template.vue";

@Component({
  mixins: [Template],
  components: {
    CancelOrderItemComponent,
    ModalItemDetailComponent,
    barcode: VueBarcode,
    PosPrescriptionModalComponent
  }
})
export class TableOrderFulfillmentComponent extends Vue {
  @Prop({ required: true })
  public item!: OrderFulfillmentPending;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  @Prop({ default: null }) public pharmacistSelected!: number | null;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public hasAccess: { [key: string]: boolean } = {};
  public orderFulfillmentStatus = OrderFulfillmentStatus;
  public itemStatus = OrderFulfillmentItemStatus;
  public loading = false;
  public IdItem!: { status: string; id: number };
  public details: OrderFulfillmentDetails[] | null = null;
  public detailsItem!: any;
  public parentItems: {} = this.item;
  public selectAllMessage: string | null = null;
  public selectedAll: boolean = false;
  public openModal: number | null = null;
  public printing = false;
  public printingLabel = false;
  public pharmacistItems: Array<{ text: string; value: number }> = [];
  public doctorsItems: Array<{ text: string; value: string }> = [];
  public defaultTemplate: TemplateEditor.TemplateModel | null = null;
  public biotrackTrace!: boolean;

  /**
   * Gets disabled button save
   */
  public get disabledSave() {
    let ret = true;
    if (this.details) {
      if (this.details.length > 1) {
        ret = !this.details.every(
          v =>
            v.checked === true || (v.checked === false && v.canceled === true)
        );
      } else {
        ret = !this.details.every(v => v.checked === true);
      }
      if (!ret && this.retailSettings.pharmacy_mode_enabled) {
        return this.checkAllPrescriptionDetail(this.details);
      }
    }
    return ret;
  }
  public disableModifyPrescription(
    item: OrderFulfillmentDetails,
    tabStatus: string
  ) {
    if (tabStatus === "CANCELED" || item.canceled) {
      return true;
    } else {
      return !this.hasPermission(policyList.modifyPrescriptionDetailsInOOF);
    }
  }

  public checkAllPrescriptionDetail(offdetails: OrderFulfillmentDetails[]) {
    for (const item of offdetails) {
      if (item.filled) {
        for (const filledOrder of item.filled) {
          if (
            !filledOrder.canceled &&
            filledOrder.product!.marijuana &&
            !this.isRequiredPrescriptionDetailAvailable(
              filledOrder.prescription_details
            )
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * Saves order
   */
  public async saveOrder() {
    try {
      const response = await orderFulfillmentService.setStatusOrderFulfillment(
        this.item.uid,
        "fill"
      );
      if (response && response.statusCancel) {
        await this.$modals.load(
          AlertComponent,
          {
            size: "fit"
          },
          {
            msgModal: {
              body: this.$t("order_fulfillment.canceled_preorder"),
              captionButton: "Accept"
            }
          }
        );
      }
      if (!response) {
        this.itsLoaded();
      }
      EventBus.$emit("loadOrdersSaved");
      EventBus.$emit("loadOrdersPending");
    } catch (e) {
      // Just to avoid uncaught error
    }
  }

  /**
   * Cancels order
   */
  public async cancelOrder() {
    await this.$modals.load(
      CancelOrderComponent,
      {
        size: "normal",
        positionX: "center",
        positionY: "center"
      },
      {
        order: cloneDeep(this.item),
        item: this.item
      }
    );

    EventBus.$emit("loadOrdersPending");
    EventBus.$emit("loadOrdersCancel");
    EventBus.$emit("loadOrdersSaved");
    this.loadData();
  }

  /**
   * Undos order
   * change the status of an acceptance order to pending
   */
  public async undoOrder() {
    const response = await orderFulfillmentService.setStatusOrderFulfillment(
      this.item.uid,
      "unfill"
    );
    if (response && response.statusCancel) {
      await this.$modals.load(
        AlertComponent,
        {
          size: "fit"
        },
        {
          msgModal: {
            body: this.$t("order_fulfillment.canceled_preorder"),
            captionButton: "Accept"
          }
        }
      );
    }

    if (!response) {
      this.itsLoaded();
    }

    EventBus.$emit("loadOrdersPending");
    EventBus.$emit("loadOrdersSaved");
  }

  /**
   * Edits table order fulfillment component
   * @param item: interface;
   */
  public async edit(item: OrderFulfillmentItem): Promise<void> {
    try {
      await this.$modals.load(
        EditOrderItemComponent,
        {
          size: "normal",
          positionX: "center",
          positionY: "center"
        },
        { item: cloneDeep(item), customer: this.item.customer }
      );
      this.loadData();
    } catch (e) {
      // Just to avoid uncaught error
    }
  }

  /**
   * Removes table order fulfillment component
   * @param item
   */
  public async remove(item: OrderFulfillmentItem) {
    try {
      await this.$modals.load(
        CancelOrderItemComponent,
        {
          size: "normal",
          positionX: "center",
          positionY: "center"
        },
        { item: cloneDeep(item), orderId: this.item.uid }
      );
      this.loadData();
    } catch (e) {
      // Just to avoid uncaught error
    }
  }

  /**
   * Dialog to show the cancel reason
   * @param item
   */
  public async info(item: OrderFulfillmentItem) {
    try {
      await this.$modals.load(
        CancelOrderItemComponent,
        {
          size: "normal",
          positionX: "center",
          positionY: "center"
        },
        { item: cloneDeep(item), orderId: this.item.uid, readOnly: true }
      );
      this.loadData();
    } catch (e) {
      // Just to avoid uncaught error
    }
  }

  public async undo(i: number) {
    this.openModal = i;
  }

  public unSelectModal() {
    this.openModal = null;
  }

  public selectModalItem(item: OrderFulfillmentItem) {
    item.canceled = false;
    this.openModal = null;
    this.loadData();
  }

  /**
   * Determines whether saved is
   * @returns
   */
  public isSaved(item: BatchInfo) {
    return item.updated === true;
  }

  /**
   * Determines whether updated is
   * @param item: interface;
   * @returns boolean
   */
  public isUpdated(item: BatchInfo) {
    return item.updated === true;
  }

  public isCanceled(item: BatchInfo) {
    return item.canceled === true;
  }

  /**
   * The function to Prints table order fulfillment component
   */
  public async print() {
    this.printing = true;
    const details = await orderFulfillmentService.pickList(
      this.item.uid,
      this.item.status
    );
    const status = this.item.status;
    EventBus.$emit("print", {
      component: OrderFulfillmentPrintComponent,
      props: {
        details,
        status
      }
    });
    this.printing = false;
  }

  public async printLabels() {
    this.printingLabel = true;
    const details = await orderFulfillmentService.getListLabels(this.item.uid);
    details.order_items = await getLabsDataForPrintLabels(details.order_items);
    EventBus.$emit("print", {
      component: CustomerLabelComponent,
      props: {
        order: details
      }
    });
    this.printingLabel = false;
  }

  /**
   * The function to Units show
   * @param unit
   * @returns string
   */
  public unitShow(unit: number) {
    return unit ? "g" : "u";
  }

  /**
   * Select/Unselect all checks
   */
  public selectAll() {
    if (this.selectedAll === false) {
      this.selectAllMessage = "order_fulfillment.unselect_all";
      if (this.details && this.details.length) {
        this.details.forEach((item, i) => {
          if (!item.canceled && item.checked === false) {
            this.checkItem(i);
          }
        });
      }
    } else {
      this.selectAllMessage = "order_fulfillment.select_all";
      if (this.details && this.details.length) {
        this.details.forEach((item, i) => {
          if (!item.canceled && item.checked === true) {
            this.checkItem(i);
          }
        });
      }
    }
    this.selectedAll = !this.selectedAll;
  }

  public async checkItem(i: number) {
    const theItem = this.details![i];
    if (
      this.retailSettings.pharmacy_mode_enabled &&
      !this.retailSettings.rx_number &&
      theItem.checked === false
    ) {
      this.$modals
        .load(
          AlertComponent,
          {
            size: "fit"
          },
          {
            msgModal: {
              title: this.$t("pmp.rxNumber_warn_title"),
              body: this.$t("pmp.rxNumber_warn_message"),
              captionButton: "OK"
            }
          }
        )
        .then(
          () => {
            theItem.checked = false;
          },
          () => {
            theItem.checked = false;
          }
        );
    } else {
      if (this.details) {
        theItem.updating = true;
        let res: null | OrderFulfillmentDetails = null;
        if (theItem.checked === false) {
          res = await orderFulfillmentService.checkUncheckOrderFulfillmentItem(
            theItem.item_uid,
            "check"
          );
        } else {
          res = await orderFulfillmentService.checkUncheckOrderFulfillmentItem(
            theItem.item_uid,
            "uncheck"
          );
        }
        if (res) {
          this.loading = true;
          theItem.filled = res.filled;
          theItem.checked = res.checked;
          this.checkSelectedDoctorAndPharamcist();
        }
        theItem.updating = false;
      }
    }
  }

  // callback function  to receive barcode
  public async onBarcodeScanned(barcode: string) {
    this.loading = true;
    const scannedResponse = await orderFulfillmentService.preOrderScan(
      barcode.trim()
    );
    if (scannedResponse === null || !scannedResponse.length) {
      await productService.renderSearchErrorModal(
        "biotrack_traceability.search_error",
        "biotrack_traceability.search_error_message",
        "",
        true,
        false,
        "ok",
        "biotrack_traceability.retail_orderfullfillment",
        "/img/icon_primary_menu_retail@2x.009e06e8.png"
      );
      this.loading = false;
      return;
    }

    const scanned: PreOrderScann[] = scannedResponse.map(scannedItem => ({
      batch_uid: scannedItem.batch_uid,
      sku: scannedItem.sku,
      in_this_location_since: scannedItem.in_this_location_since,
      available_quantity: sumObjArrayBy(
        scannedItem.on_sale,
        onSale => +onSale.quantity_value
      )
    }));

    if (scanned.length === 0) {
      this.showScanError("scan_status.FOUND");
      return;
    }

    const productSKU = getParentSKU(scanned[0].sku);
    const detailsInOrder = this.details!.filter(
      item => item.product.sku === productSKU
    );

    if (detailsInOrder.length === 0) {
      await productService.renderSearchErrorModal(
        "biotrack_traceability.search_error",
        "biotrack_traceability.search_error_message",
        "",
        true,
        false,
        "ok",
        "biotrack_traceability.retail_orderfullfillment",
        "/img/icon_primary_menu_retail@2x.009e06e8.png"
      );
      this.showScanError("order_fulfillment.itemScannedError");
      return;
    }

    const unfilledDetailsInOrder = this.getUnfilledItems(detailsInOrder);

    if (unfilledDetailsInOrder.length === 0) {
      this.showScanError("order_fulfillment.itemWasFilled");
      return;
    }

    // @ts-ignore
    const details: DetailsInScan[] = unfilledDetailsInOrder.map(item => ({
      item_uid: item.item_uid,
      productSKU: item.product.sku,
      total_filled: item.total_filled,
      ordered: item.ordered![0],
      filled: item.filled
    }));

    const currentData = this.getCurrentScannedData(details, scanned);

    if (!currentData) {
      this.showScanError("scan_status.FOUND_WITH_ZERO_QUANTITY");
      return;
    }

    await this.updateOrderWithScanned(currentData.detail, currentData.scanned);
    this.loadData();
  }

  public availablePrescriptionDetailCondition(item: OrderFulfillmentDetails) {
    if (item && item.product && item.ordered && !this.loading) {
      const productName = item.product!.name;
      const itemIndex = item.ordered.findIndex(
        (order: Ordered) => order.product.name === productName
      );
      const prescriptionData = item.ordered[itemIndex]!.prescription_details;
      return this.isRequiredPrescriptionDetailAvailable(prescriptionData);
    }
  }

  public isRequiredPrescriptionDetailAvailable(
    prescriptionData?: PrescriptionDetails | null
  ) {
    if (
      prescriptionData &&
      prescriptionData.quantity &&
      prescriptionData.quantity_unit_measure &&
      prescriptionData.timeframe_unit_measure &&
      prescriptionData.dosage_unit_measure &&
      prescriptionData.days_supply &&
      prescriptionData.dosage_take &&
      prescriptionData.dosage_to &&
      prescriptionData.dosage_timeframe_take &&
      prescriptionData.dosage_timeframe_to &&
      prescriptionData.pharmacist_id &&
      prescriptionData.doctor_id
    ) {
      return true;
    } else {
      return false;
    }
  }

  public async onOpenFilledItemPrescriptionDetails(
    filled: Ordered,
    item: OrderFulfillmentDetails,
    tabStatus: string
  ) {
    const detailsData = cloneDeep(this.details);
    let prescriptionDetail: PrescriptionDetails | null | undefined =
      defaultPrescriptionDetails || null;
    const batchUid = filled.batch_uid;
    const prescriptionNumber = item.prescription_no;
    if (filled.prescription_details!) {
      prescriptionDetail = filled.prescription_details;
    }
    if (
      !this.isRequiredPrescriptionDetailAvailable(filled.prescription_details)
    ) {
      prescriptionDetail!.pharmacist_id = this.pharmacistSelected;
      prescriptionDetail!.doctor_id = this.item.customer
        ? this.item.customer.profile
          ? this.item.customer.profile[0].doctor_id || null
          : null
        : null;
    }

    let isSameBatchAvailable = false;
    if (detailsData) {
      detailsData.forEach((items: OrderFulfillmentDetails) => {
        if (items.item_uid !== item.item_uid) {
          const orderdFilter = items.ordered!.filter(
            order => String(order.batch_uid) === String(filled.batch_uid)
          );
          if (orderdFilter.length) {
            isSameBatchAvailable = true;
          } else {
            const productName = item.product!.name;
            const itemIndex = item.ordered!.findIndex(
              (order: Ordered) => order.product.name === productName
            );
            if (item.ordered!.length) {
              const filledFilter = items.filled!.filter(
                filledOrder =>
                  String(filledOrder.batch_uid) ===
                  String(item.ordered![itemIndex].batch_uid)
              );
              if (filledFilter.length) {
                isSameBatchAvailable = true;
              }
            }
          }
        }
      });
    }
    this.$modals
      .load(
        PosPrescriptionModalComponent,
        {
          size: "fit",
          positionY: "top"
        },
        {
          model: prescriptionDetail,
          pageType: "oofPage",
          filfillmentOrderDetail: item,
          batchUid,
          pharmacistItems: this.pharmacistItems,
          doctorsItems: this.doctorsItems,
          disabled:
            this.disableModifyPrescription(item, tabStatus) ||
            tabStatus === "CANCELED" ||
            item.canceled,
          isSameBatchAvailable,
          prescriptionNumber: prescriptionNumber || null
        }
      )
      .then(
        () => {
          this.loading = true;
          this.loadData();
        },
        () => {
          /** Nothing to do */
        }
      );
  }

  public async onOpenPrescriptionDetails(
    item: OrderFulfillmentDetails,
    tabStatus: string
  ) {
    let prescriptionNumber;
    const detailsData = cloneDeep(this.details);
    const productName = item.product!.name;
    const itemIndex = item.ordered!.findIndex(
      (order: Ordered) => order.product.name === productName
    );
    let prescriptionDetail: PrescriptionDetails | null | undefined =
      defaultPrescriptionDetails || null;
    let batchUid;
    let isSameBatchAvailable = false;
    if (itemIndex !== -1) {
      batchUid = item.ordered![itemIndex].batch_uid;
      if (item.ordered![itemIndex]!.prescription_details!) {
        prescriptionDetail = item.ordered![itemIndex]!.prescription_details;
      }
      prescriptionNumber = item.prescription_no;
      if (detailsData) {
        detailsData.forEach((items: OrderFulfillmentDetails) => {
          if (items.item_uid !== item.item_uid) {
            const filledFilter = items.filled!.filter(
              filledOrder =>
                String(filledOrder.batch_uid) ===
                String(item.ordered![itemIndex].batch_uid)
            );

            if (filledFilter.length) {
              isSameBatchAvailable = true;
            }
          }
        });
      }
    }
    if (!this.availablePrescriptionDetailCondition(item)) {
      prescriptionDetail!.pharmacist_id = this.pharmacistSelected;
      prescriptionDetail!.doctor_id = this.item.customer
        ? this.item.customer.profile
          ? this.item.customer.profile[0].doctor_id || null
          : null
        : null;
    }

    this.$modals
      .load(
        PosPrescriptionModalComponent,
        {
          size: "fit",
          positionY: "top"
        },
        {
          model: prescriptionDetail,
          pageType: "oofPage",
          filfillmentOrderDetail: item,
          batchUid,
          pharmacistItems: this.pharmacistItems,
          doctorsItems: this.doctorsItems,
          disabled:
            this.disableModifyPrescription(item, tabStatus) ||
            tabStatus === "CANCELED" ||
            item.canceled,
          isSameBatchAvailable,
          prescriptionNumber: prescriptionNumber || null
        }
      )
      .then(
        () => {
          this.loading = true;
          this.loadData();
        },
        () => {
          /** Nothing to do */
        }
      );
  }

  public async checkSelectedDoctorAndPharamcist() {
    if (this.details) {
      for (const items of this.details) {
        /**
         * Load Selected product Detail
         */
        const productDetail: Product = await productService.findBySkuId(
          items.product!.sku,
          {
            load_has_batches: true
          }
        );
        if (items.filled && items.filled.length) {
          for (let itemFilled of items.filled) {
            itemFilled = await this.setFilledItemsPrescritpionDetail(
              productDetail,
              itemFilled,
              items
            );
          }
        } else {
          const productName = items.product!.name;
          const itemIndex = items.ordered!.findIndex(
            (order: Ordered) => order.product.name === productName
          );
          if (itemIndex !== -1) {
            items.ordered![
              itemIndex
            ] = await this.setFilledItemsPrescritpionDetail(
              productDetail,
              items.ordered![itemIndex],
              items
            );
          }
        }
      }
    }
    this.loading = false;
  }

  public async setFilledItemsPrescritpionDetail(
    productDetail: Product,
    orderItems: Ordered,
    orderDetail: OrderFulfillmentDetails
  ): Promise<Ordered> {
    let prescriptionDetail: PrescriptionDetails | null | undefined =
      defaultPrescriptionDetails || null;
    orderItems.product.marijuana = productDetail.marijuana;
    if (productDetail.marijuana) {
      if (orderItems!.prescription_details!) {
        prescriptionDetail = orderItems!.prescription_details;
      } else {
        prescriptionDetail =
          productDetail.prescription_detail || defaultPrescriptionDetails;
      }
      if (prescriptionDetail) {
        if (!!prescriptionDetail.pharmacist_id) {
          const pharmacistIndex = this.pharmacistItems.findIndex(
            pharmacist =>
              Number(pharmacist.value) ===
              Number(prescriptionDetail!.pharmacist_id)
          );
          if (pharmacistIndex === -1) {
            prescriptionDetail.pharmacist_id = null;
          }
        }

        if (!!prescriptionDetail.doctor_id) {
          const doctorIndex = this.doctorsItems.findIndex(
            doctor =>
              Number(doctor.value) === Number(prescriptionDetail!.doctor_id)
          );
          if (doctorIndex === -1) {
            prescriptionDetail.doctor_id = null;
          }
        }
      }

      if (!prescriptionDetail!.pharmacist_id) {
        prescriptionDetail!.pharmacist_id = this.pharmacistSelected;
      }
      if (!prescriptionDetail!.doctor_id) {
        prescriptionDetail!.doctor_id = this.item.customer
          ? this.item.customer.profile
            ? this.item.customer.profile[0].doctor_id || null
            : null
          : null;
      }
      orderItems!.prescription_details = prescriptionDetail;
      if (
        prescriptionDetail &&
        this.isRequiredPrescriptionDetailAvailable(prescriptionDetail)
      ) {
        const itemUid = orderDetail.item_uid || null;
        prescriptionDetail.batch_uid = String(orderItems.batch_uid);
        if (prescriptionDetail.pre_order_item_id) {
          delete prescriptionDetail.pre_order_item_id;
        }
        if (prescriptionDetail.pre_order_id) {
          delete prescriptionDetail.pre_order_id;
        }
        if (prescriptionDetail.id) {
          delete prescriptionDetail.id;
        }
        await orderService.updatePreOrderPrescriptionDetails(
          prescriptionDetail,
          itemUid
        );
      }
    }
    return orderItems;
  }
  public prescriptionIconConditions(item: OrderFulfillmentDetails) {
    const productName = item.product!.name;
    const itemIndex = item.ordered!.findIndex(
      (order: Ordered) => order.product.name === productName
    );
    if (itemIndex !== -1) {
      if (
        this.retailSettings.pharmacy_mode_enabled &&
        item.ordered![itemIndex].product.marijuana
      ) {
        return true;
      }
    }
    return false;
  }
  /**
   * Load the Doctor and Pharmacist Data
   */
  public async getDoctorsAndPharmacist() {
    const [doctorsList, pharmacistList] = await Promise.all([
      customerService.getDoctors(),
      pharmacistService.getAll()
    ]);
    this.pharmacistItems = pharmacistList.map((item: PharmacistInterface) => ({
      text: `${item.pharmacist_first_name}  ${item.pharmacist_last_name} ${
        item.pharmacist_title ? ", " + item.pharmacist_title : ""
      } `,
      value: Number(item.user_id)
    }));
    this.doctorsItems = doctorsList.map((item: Doctor) => ({
      text: `${item.first_name} ${item.last_name} ${
        item.title ? ", " + item.title : ""
      }`,
      value: String(item.id)
    }));
    this.checkSelectedDoctorAndPharamcist();
  }
  /** Is loaded? */
  public async itsLoaded() {
    await this.$modals
      .load(
        AlertComponent,
        {
          size: "fit"
        },
        {
          msgModal: {
            body:
              this.item.status === "PENDING"
                ? this.$t(
                    "order_fulfillment.alert_update_loaded_pending"
                  ).toString()
                : this.$t(
                    "order_fulfillment.alert_update_loaded_filled"
                  ).toString(),
            captionButton: "Accept"
          }
        }
      )
      .then(captionButton => {
        setTimeout(() => {
          window.location.reload();
        }, 2);
      });
  }

  protected async updateOrderWithScanned(
    detail: DetailsInScan,
    scanned: PreOrderScann
  ) {
    const isItemFilled = detail.filled.find(
      item => item.batch_uid === scanned.batch_uid
    );

    const filled = isItemFilled
      ? this.updateFilled(detail.ordered, detail, scanned)
      : [
          ...detail!.filled,
          this.addScannedToFilled(detail.ordered, detail, scanned)
        ];

    await orderFulfillmentService.updateOrderPending(
      filled.map(item => {
        return {
          sku: (item.product! as Product).sku,
          quantity: Number(item.quantity)
        };
      }),
      detail.item_uid
    );
  }

  protected getCurrentScannedData(
    details: DetailsInScan[],
    scanned: PreOrderScann[]
  ): CurrentScannedData | null {
    const checkOrderedScanned = (
      detailItem: DetailsInScan,
      scannedItem: PreOrderScann
    ) => {
      return (
        detailItem.ordered.batch_uid === scannedItem.batch_uid &&
        (detailItem.filled.length === 0 || !!scannedItem.available_quantity)
      );
    };

    // bacth_uid or tracking_id scanning
    if (scanned.length === 1) {
      const detail = details.find(detailItem =>
        checkOrderedScanned(detailItem, scanned[0])
      );

      if (detail) {
        return { scanned: scanned[0], detail };
      } else if (scanned[0].available_quantity > 0) {
        return { scanned: scanned[0], detail: details[0] };
      }

      return null;
    }

    // external barcode with ordered scanned
    let orderedResult: CurrentScannedData | null = null;
    scanned.forEach(scannedItem => {
      if (orderedResult === null) {
        const detail = details.find(detailItem =>
          checkOrderedScanned(detailItem, scannedItem)
        );

        if (detail) {
          orderedResult = { detail, scanned: scannedItem };
        }
      }
    });

    if (orderedResult) {
      return orderedResult;
    }

    // external barcode without ordered scanned, get older batch
    const sortedScanned = scanned
      .filter(scan => scan.available_quantity > 0)
      .sort((a, b) => {
        const aDate = new Date(a.in_this_location_since);
        const bDate = new Date(b.in_this_location_since);
        return aDate.getTime() - bDate.getTime();
      });

    if (sortedScanned.length > 0) {
      return { detail: details[0], scanned: sortedScanned[0] };
    }

    return null;
  }

  protected getUnfilledItems(
    details: OrderFulfillmentDetails[]
  ): Array<OrderFulfillmentDetails & { total_filled: number }> {
    return details
      .map(item => ({
        ...item,
        total_filled: item.filled!.reduce(
          (total, orderFilled) => (total += Number(orderFilled.quantity)),
          0
        )
      }))
      .filter(item => item.total_filled < item.ordered![0].quantity);
  }

  protected showScanError(error: string): void {
    messagesService.showMessage("fal fa-exclamation-triangle", error, "error");
    this.loading = false;
  }

  protected updateFilled(
    ordered: OrderFulfillmentOrdered,
    detail: DetailsInScan,
    scanned: PreOrderScann
  ): OrderFulfillmentFilled[] {
    const current = detail.filled.find(
      item => item.batch_uid === scanned.batch_uid
    );

    if (current) {
      current.quantity =
        Number(current.quantity) +
        this.getScannedQuantity(ordered, detail, scanned);
    }

    return detail.filled;
  }

  protected getScannedQuantity(
    ordered: OrderFulfillmentOrdered,
    detail: DetailsInScan,
    scanned: PreOrderScann
  ): number {
    if (ordered.in_store_quantity_unit === "u") {
      return 1.0;
    }

    // If detail not filled all ordered was reserved
    if (detail.filled.length === 0 && ordered.batch_uid === scanned.batch_uid) {
      return Number(detail.ordered.quantity);
    }

    const quantity = truncate(
      Number(ordered.quantity) - detail.total_filled,
      2
    );

    return scanned.available_quantity >= quantity
      ? quantity
      : scanned.available_quantity;
  }

  protected addScannedToFilled(
    ordered: OrderFulfillmentOrdered,
    detail: DetailsInScan,
    scanned: PreOrderScann
  ): Partial<OrderFulfillmentFilled> {
    return {
      batch_uid: scanned.batch_uid,
      quantity: this.getScannedQuantity(ordered, detail, scanned),
      product: {
        sku: scanned.sku
      }
    };
  }

  /**
   * Load the order table data
   */
  protected async loadData() {
    if (this.hasBioTrackTraceIntegrations && this.bioTrackTraceEnabled) {
      this.biotrackTrace = true;
    }
    this.loading = true;
    this.detailsItem = await orderFulfillmentService.searchOrderFulfillmentItems(
      this.item.uid
    );
    this.details = this.detailsItem.data.data;
    if (this.details && this.details.length) {
      this.details = this.details.map(item => {
        // Since we are adding a new attribute to the objects
        // We need to "create" new ones in order to get reactiveness
        return { ...item, updating: false };
      });
      if (this.disabledSave === false) {
        this.selectAllMessage = "order_fulfillment.unselect_all";
        this.selectedAll = true;
      } else {
        this.selectAllMessage = "order_fulfillment.select_all";
        this.selectedAll = false;
      }
    }
    this.getDoctorsAndPharmacist();
  }

  protected async mounted() {
    this.hasAccess = {
      restockCancelledOrder: this.hasPermission(
        policyList.restockCancelledOrder
      ),
      cancelSavedOrder: this.hasPermission(policyList.cancelSavedOrder),
      cancelPendingOrder: this.hasPermission(policyList.cancelPendingOrder),
      cancelLineItem: this.hasPermission(policyList.cancelLineItem)
    };
    this.loadData();
    EventBus.$on("loadDetailsOrder", this.loadData);
    this.parentItems = this.item;

    EventBus.$on("onChangePharmacistOnDuty", (pharmacistId: number | null) => {
      this.loading = true;
      this.loadData();
    });
  }
}
