import { EventBus } from "@/event-bus";
import {
  OrderFulfillmentDetails,
  OrderFulfillmentPending,
  OrderFulfillmentStatus
} from "@/interfaces/orderFulfillment";
import { pusherEvents } from "@/internal";
import { CancelledTableHeaders } from "@/metadata/orderFulfillment";
import { orderFulfillmentActionService } from "@/services/orderFulfillment.action.service";
import { orderFulfillmentService } from "@/services/orderFulfillment.service";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import {
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination,
  TablePaginationDefault
} from "helix-vue-components";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { CancelOrderComponent } from "../cancelOrder/CancelOrder.component";
import { TableOrderFulfillmentComponent } from "../tableOrderFulfillment/tableOrderFulfillment.component";
import Template from "./CancelledOrders.template.vue";
/**
 * Components
 */
@Component({
  mixins: [Template],
  components: { TableComponent, TableOrderFulfillmentComponent },
  inject: ["$changes"]
})
export default class CancelledOrdersComponent extends Vue {
  @Prop({ required: true }) public data!: {};
  @Prop({ default: "" }) public search!: string;
  @Prop({ default: false }) public onDisplay!: boolean;
  @Prop({ default: null }) public pharmacistSelected!: number | null;
  public headers = CancelledTableHeaders;
  public orders: OrderFulfillmentPending[] = [];
  public pagination: TablePagination = { ...TablePaginationDefault };
  public statusOrders: { CANCELED: string } = OrderFulfillmentStatus;
  public loading = false;

  /**
   * Edition modal data.
   */
  public model: OrderFulfillmentDetails | null = null;

  /**
   * Row actions data for the table.
   */
  public rowActions: TableAction[] = orderFulfillmentActionService.getRowActions(
    this.info
  );

  @Watch("search")
  public onFiltersChange() {
    this.pagination = {
      ...TablePaginationDefault,
      itemsPerPage: this.pagination.itemsPerPage
    };
    this.loadOrders();
  }

  public filter(header: TableHeader) {
    const h = header;

    const sort = `${!h.descending ? "-" : ""}${h.value || ""}`;
    this.loadOrders(sort);
  }

  /**
   * Changes pagination : Responsible for switching to another page
   * @param pagination
   */
  public changePagination(pagination: TablePagination) {
    this.pagination = pagination;
    this.loadOrders();
  }

  /**
   * Dialog to show the cancel reason
   * @param item
   */
  public async info(order: OrderFulfillmentDetails) {
    this.model = { ...order };
    try {
      await this.$modals.load(
        CancelOrderComponent,
        {
          size: "normal",
          positionX: "center",
          positionY: "center"
        },
        {
          order: this.model,
          readOnly: true
        }
      );
    } catch (e) {
      // Just to avoid uncaught error on close
    }
  }

  /**
   * mounted : The mounted a native function from vuejs,
   * documentation: https://vuejs.org/v2/guide/instance.html
   */
  protected mounted() {
    this.$changes.watch(pusherEvents.orderFulfillment, this.loadOrders);
    EventBus.$on("loadOrdersCancel", this.loadOrders);
    const unwatch = this.$watch("onDisplay", () => {
      this.loadOrders();
      unwatch();
    });
  }

  /**
   * Loads orders : Responsible for loading the list of accepted orders
   */
  private async loadOrders(sort?: string) {
    this.loading = true;
    const params = {
      status: this.statusOrders.CANCELED.toLowerCase(),
      pagination: this.pagination,
      sort,
      search: this.search
    };
    this.orders = (await orderFulfillmentService.getOrders(params)).map(
      order => {
        const dateFromHour = order.pick_up_time_from
          ? fnsFormatDate(
              new Date(order.pick_up_time_from),
              FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME
            )
          : "";

        const dateToHour = order.pick_up_time_to
          ? fnsFormatDate(new Date(order.pick_up_time_to), FNS_DATE_FORMATS.LT)
          : "";
        return {
          ...order,
          name: order.customer.name,
          customer: {
            ...order.customer,
            avatar: null
          },
          pick_up_date_range:
            dateFromHour && dateToHour ? `${dateFromHour} - ${dateToHour}` : ""
        };
      }
    );
    this.pagination = await orderFulfillmentService.getPagination(true);
    this.$changes.watch(pusherEvents.orderFulfillment, this.loadOrders);
    this.loading = false;
  }
}
