import CancelledOrdersComponent from "@/components/retail/orderFulfillment/cancelledOrders/CancelledOrders.component";
import PreOrderPendingComponent from "@/components/retail/orderFulfillment/preOrderPending/PreOrderPending.component";
import { SavedOrdersComponent } from "@/components/retail/orderFulfillment/savedOrders/SavedOrders.component";
import StatusFiltersComponent from "@/components/sharedComponents/statusFilters/StatusFilters.component";
import TraceabilityConfirm from "@/components/sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import {
  OrderFulfillmentStatus,
  preOrderFilledFilterDefault,
  PreOrderFilters,
  preOrderPendingFilterDefault
} from "@/interfaces/orderFulfillment";
import { PharmacistInterface } from "@/interfaces/pharmacist";
import { RetailSettings } from "@/interfaces/retailSettings";
import { User } from "@/interfaces/user";
import { EventBus } from "@/internal";
import {
  BasicTableHeaders,
  CancelledTableHeaders,
  SavedTableHeaders
} from "@/metadata/orderFulfillment";
import { pharmacistService } from "@/services/pharmacist.service";
import { productService } from "@/services/product.service";
import { PageNavAction } from "@/types/types";
import { TablePagination, TablePaginationDefault } from "helix-vue-components";
import Cookie from "js-cookie";
import isEqual from "lodash/isEqual";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./OrderFulfillmentList.template.vue";

export enum OrderFulfillmentTabs {
  PreOrderPending = "PreOrderPending",
  SavedOrders = "PreOrdersSaved",
  CancelledOrders = "CancelledOrders"
}
const defaultFilters: PreOrderFilters[] = [
  { ...preOrderPendingFilterDefault },
  { ...preOrderFilledFilterDefault }
];
@Component({
  mixins: [Template],
  components: {
    [OrderFulfillmentTabs.PreOrderPending]: PreOrderPendingComponent,
    [OrderFulfillmentTabs.SavedOrders]: SavedOrdersComponent,
    [OrderFulfillmentTabs.CancelledOrders]: CancelledOrdersComponent,
    StatusFiltersComponent
  }
})
export default class OrderFulfillmentListComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  @Getter("user", { namespace: "AuthModule" })
  public currentLoggedUser!: User;
  public pagination: TablePagination = { ...TablePaginationDefault };
  public active = OrderFulfillmentTabs.PreOrderPending;
  public tabs = OrderFulfillmentTabs;
  public statusOrders: { [key: string]: any } = OrderFulfillmentStatus;
  // For lazy loading the tabs content (active is an index number when modified by the component)
  public tabsArray = [
    OrderFulfillmentTabs.PreOrderPending,
    OrderFulfillmentTabs.SavedOrders,
    OrderFulfillmentTabs.CancelledOrders
  ];
  public tabsComponents: any[] = [];
  public activeTab = 0;
  public tableColors: string[] = ["#f2f2f2", "#f2f2f2", "#f2f2f2"];
  // Pharmacist On Duty field
  public pharmacistSelected: number | null = null;
  public pharmacistItems: Array<{ text: string; value: number }> = [];

  public showFilters: boolean = false;
  public filters: PreOrderFilters[] = [...defaultFilters];
  public search: string = "";
  public pendingTotal: number = 0;
  public filledTotal: number = 0;

  public assignSelectedPharmacist() {
    EventBus.$emit("onChangePharmacistOnDuty", this.pharmacistSelected);
    Cookie.set("oof_pharmacist", String(this.pharmacistSelected), {
      domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
    });
  }

  public onBarcodeScanned(code: string) {
    this.search = code;
    this.onSearch(code);
  }

  public async onSearch(term: string) {
    this.search = term;
  }

  public onClose(update: boolean) {
    if (update) {
      this.onSearch("");
    }
  }

  protected async setTabsComponents() {
    this.tabsComponents = [
      {
        component: OrderFulfillmentTabs.PreOrderPending,
        filters: [
          {
            key: "online_pending",
            statusColor: "red-wine-dark",
            label: "store_customers.online_pending"
          },
          {
            key: "call_in_pending",
            statusColor: "red-wine",
            label: "store_customers.call_in_pending"
          },
          {
            key: "in_store_pending",
            statusColor: "red-wine-light",
            label: "store_customers.in_store_pending"
          }
        ],
        data: {
          headers: BasicTableHeaders,
          colors: this.tableColors
        }
      },
      {
        component: OrderFulfillmentTabs.SavedOrders,
        filters: [
          {
            key: "online_filled",
            statusColor: "purple-dark",
            label: "store_customers.online_filled"
          },
          {
            key: "call_in_filled",
            statusColor: "purple",
            label: "store_customers.call_in_filled"
          },
          {
            key: "in_store_filled",
            statusColor: "purple-light",
            label: "store_customers.in_store_filled"
          }
        ],
        data: {
          headers: SavedTableHeaders,
          colors: this.tableColors
        }
      },
      {
        component: OrderFulfillmentTabs.CancelledOrders,
        data: {
          headers: CancelledTableHeaders,
          colors: this.tableColors
        }
      }
    ];
    if (this.$route.query.type === "CALL_IN_FILLED_PREORDER") {
      setTimeout(() => {
        this.activeTab = 1;
      }, 100);
    }
  }

  protected filtersCallback() {
    this.showFilters = !this.showFilters;

    if (!this.showFilters) {
      defaultFilters.forEach((filter, index) => {
        if (!isEqual(filter, this.filters[index])) {
          this.filters[index] = { ...filter };
        }
      });
    }
  }

  protected onFilter(filter: any) {
    const filters = [...this.filters];
    filters[this.activeTab] = filter;
    this.filters = filters;
  }

  protected async created() {
    // @ts-ignore
    this.$barcodeScanner.init(this.onBarcodeScanned);
  }

  protected async mounted() {
    this.setPageNav({
      title: "order_fulfillment.title",
      rightActions: {
        generalActions: () => {
          return [
            {
              icon: "fal fa-search",
              id: "btn_search_prod",
              otherComponent: {
                name: "TableSearchComponent"
              }
            },
            {
              icon: "fal fa-filter",
              id: "btn_filter_oof",
              action: this.filtersCallback
            }
          ];
        },
        onSearch: this.onSearch,
        onClose: this.onClose
      }
    });
    this.setTabsComponents();
    if (this.currentRetailSettings.pharmacy_mode_enabled) {
      const assignedPharmacistUserPayload =
        Cookie.get("oof_pharmacist") || null;
      const pharmacistList = await pharmacistService.getAll();
      this.pharmacistItems = pharmacistList.map(
        (item: PharmacistInterface) => ({
          text: `${item.pharmacist_first_name}  ${item.pharmacist_last_name} ${
            item.pharmacist_title ? ", " + item.pharmacist_title : ""
          } `,
          value: Number(item.user_id)
        })
      );

      if (
        assignedPharmacistUserPayload &&
        assignedPharmacistUserPayload !== "undefined"
      ) {
        this.pharmacistSelected = Number(assignedPharmacistUserPayload) || 0;
      } else {
        const index = pharmacistList.findIndex(
          item => item.user_id === this.currentLoggedUser.id
        );
        if (index !== -1) {
          this.pharmacistSelected = pharmacistList[index].user_id || 0;
          Cookie.set("oof_pharmacist", String(this.pharmacistSelected), {
            domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
          });
        }
      }
    }
  }

  protected beforeDestroy() {
    // @ts-ignore
    this.$barcodeScanner.destroy();
  }

  protected onInboxTotalsUpdate(value: {
    quantity: number;
    inbox: "pending" | "filled";
  }) {
    if (value.inbox === "pending") {
      this.pendingTotal = value.quantity;
    } else {
      this.filledTotal = value.quantity;
    }
  }
}
