import { FilterModel, FilterStatus } from "@/interfaces/statusFilters";
import debounce from "lodash/debounce";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./StatusFilters.template.vue";

@Component({
  mixins: [Template]
})
export default class StatusFiltersComponent extends Vue {
  @Prop({ required: true })
  public statuses!: FilterStatus[];
  @Prop({ required: true })
  public values!: FilterModel;
  @Prop({ required: true })
  public showFilters!: boolean;
  @Prop({ default: "inactive" })
  public inactiveClass!: string;
  protected filters: FilterModel = {};
  protected dFilter = debounce(c => c.$emit("filter", c.filters), 500);

  public toggle(key: string) {
    const filter: FilterModel = {};
    filter[key] = !this.filters[key];
    this.filters = { ...this.filters, ...filter } as FilterModel;
    this.filter();
  }

  public filter() {
    this.dFilter(this);
  }

  @Watch("values")
  protected setFiltersModel() {
    this.filters = this.values;
  }

  protected mounted(): void {
    this.setFiltersModel();
  }
}
