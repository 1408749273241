import { i18n } from "@/plugins/i18n";
import { FNS_DATE_FORMATS } from "@/utils/date-fns.utils";
import { TableFieldType, TableHeader } from "helix-vue-components";

export const BasicTableHeaders: TableHeader[] = [
  {
    label: i18n.t("order_fulfillment.order_id").toString(),
    fieldComponent: TableFieldType.string,
    value: "uid"
  },
  {
    label: "",
    fieldComponent: TableFieldType.image,
    class: "tdt__headers__fieldImage",
    value: "customer.avatar"
  },
  {
    label: i18n.t("order_fulfillment.status").toString(),
    fieldComponent: TableFieldType.orderStatus,
    value: "readable_status"
  },
  {
    label: i18n.t("order_fulfillment.name").toString(),
    fieldComponent: TableFieldType.string,
    value: "customer.name"
  },
  {
    label: i18n.t("order_fulfillment.source").toString(),
    fieldComponent: TableFieldType.custom,
    value: "source_name"
  },
  {
    label: i18n.t("order_fulfillment.order_time").toString(),
    fieldComponent: TableFieldType.fnsDate,
    dateFormat: FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME,
    value: "confirmed_at",
    sortable: true
  },
  {
    label: i18n.t("order_fulfillment.estimated_pick_up_time").toString(),
    fieldComponent: TableFieldType.string,
    value: "pick_up_date_range"
  }
];

export const SavedTableHeaders: TableHeader[] = [
  ...BasicTableHeaders,
  {
    label: i18n.t("order_fulfillment.filled_time").toString(),
    fieldComponent: TableFieldType.fnsDate,
    value: "filled_at"
  }
];

export const CancelledTableHeaders: TableHeader[] = [
  ...BasicTableHeaders,
  {
    label: i18n.t("order_fulfillment.filled_time").toString(),
    fieldComponent: TableFieldType.fnsDate,
    value: "filled_at",
    options: { custom_null_response: "--" }
  },
  {
    label: i18n.t("order_fulfillment.canceled_time").toString(),
    fieldComponent: TableFieldType.fnsDate,
    value: "canceled_at"
  }
];
