import { PrescriptionDetails } from "@/interfaces/prescriptionDetails";
import { Product } from "@/interfaces/product";
import { Batch, PBSummary } from "./batch";
export interface OrderFulfillmentItem {
  ordered: Ordered[] | null;
  filled: Ordered[] | null;
  item_uid: string;
  canceled: boolean;
  cancel_reason: string | null;
  updated: boolean;
  checked: boolean | null;
  updating: boolean;
}
export interface OrderFulfillmentOrdered {
  batch_id: string;
  batch_uid: string;
  quantity: string | number;
  in_store_quantity_unit: string;
  in_store_quantity_value: number;
  batch_type: string;
  canceled: boolean;
  canceled_at: string;
  item_uid: string;
  product?: object;
  unit: string;
  usable_weight_unit: string;
  prescription_details?: PrescriptionDetails | null;
}
export interface OrderFulfillmentFilled {
  batch_id: string;
  batch_uid: string;
  quantity: string | number;
  in_store_quantity_unit: string;
  in_store_quantity_value: number;
  batch_type: string;
  canceled: boolean;
  canceled_at: string;
  item_uid: string;
  product?: object;
  unit: string;
  usable_weight_unit: string;
  prescription_details?: PrescriptionDetails | null;
}
export interface OrderFulfillmentDetails extends OrderFulfillmentItem {
  uid: string;
  order_fulfillment_items: BatchInfo[];
  accepted_at: string;
  canceled_at: string;
  created_at: string;
  checked: boolean;
  product: {
    name: string | null;
    sku: string;
  };
  sku: string | null | undefined;
  ordered: Ordered[] | null;
  prescription_no?: number | null;
  filled: Ordered[] | null;
  canceled: boolean;
  cancel_reason: string | null;
  updated: boolean;
}

export interface DetailsInScan {
  item_uid: string;
  productSKU: string;
  total_filled: number;
  ordered: OrderFulfillmentOrdered;
  filled: OrderFulfillmentFilled[];
}

export interface CurrentScannedData {
  detail: DetailsInScan;
  scanned: PreOrderScann;
}
export interface OrderFulfillmentItemDeleteRequest {
  order_fulfillment_items: OrderFulfillmentItemDelete[];
}

export interface OrderFulfillmentItemDeleteUpdate {
  order_fulfillment_items: OrderFulfillmentItemUpdate[];
}

export interface OrderFulfillmentItemDelete {
  id: number;
  status: string;
  cancel_reason: string;
}
export interface OrderFulfillmentItemUpdate {
  id: number;
  batches: Array<{ id: number | null; quantity: number }>;
}
export interface OrderFulfillmentDetailsNew extends OrderFulfillmentItem {
  order: string | null;
  order_fulfillment_items: BatchInfo[];
}

export interface OrderCustomer {
  birthday: string;
  first_name: string;
  id: string;
  last_name: string;
  middle_name: string;
  avatar_medium_url: string;
  avatar_thumb_url: string;
  avatar_original_url: string;
}

export interface BatchInfo {
  item_uid: string | null;
  product: Product;
  batch_uid: string | null;
  sku: string;
  uid: string;
  id: number;
  quantity: number;
  batch_id: string;
  cancel_reason: string | null;
  product_name: string;
  item_id: number;
  product_id: number;
  batch: BatchInfo;
  status: OrderFulfillmentItemStatus | null;
  updated: boolean;
  canceled: boolean;
  product_unit: string | null;
  batches?: Array<{
    id: number;
    batch_uid: string;
    product: string;
    product_id: number;
    pre_order_item_uid: number | null;
    quantity: number;
    product_unit: string | null;
  }>;
  ordered?: OrderedFilled[];
  filled?: OrderedFilled[];
}

export interface OrderedFilled {
  productBatches?: ProductBatch[];
  batchSelected?: ProductBatch | null;
  batch_uid: string;
  quantity: number;
  secondary_id?: string;
  in_store_quantity_unit: string;
  in_store_quantity_value: number;
  biotrack_traceability_id?: string;
}

export interface EditOrderReserved {
  [batchUid: string]: number;
}

export interface OrderFulfillmentPending {
  order_in_sync: boolean;
  update_loaded: boolean;
  uid: string;
  status: OrderFulfillmentStatus;
  name: any;
  avatar_original_url?: string | null;
  source: string;
  created_at: string;
  filled_at: string;
  pick_up_time_from: string;
  pick_up_time_to: string;
  customer?:
    | Array<{
        name: string | null;
      }>
    | any;
}

export enum OrderFulfillmentStatus {
  PENDING = "PENDING",
  ACCEPTED = "FILLED",
  CANCELED = "CANCELED"
}

export enum OrderFulfillmentStatusFormated {
  PENDING = "PENDING",
  ACCEPTED = "PRE-ORDER FILLED",
  CANCELED = "CANCELED"
}

export enum OrderFulfillmentItemStatus {
  UPDATED = "UPDATED",
  CANCELED = "CANCELED"
}

/**
 * Pre order interface is used on the POS
 */
export interface PreOrder {
  oauth_app: {
    name: string;
    oauth_app_id: string;
    status: "ENABLED" | "DISABLED";
  };
  order_in_sync?: boolean;
  uid: string;
  status: string;
  source: string;
  vendor: string;
  items: Item[];
  readable_status: string;
  readable_source: string;
  created_at: string;
  confirmed_at: string;
  filled_at?: any;
  loaded_at?: any;
  canceled_at?: any;
  restocked_at?: any;
  restocked: boolean;
  updated: boolean;
  pick_up_time_from: string;
  pick_up_time_to: string;
  cancel_reason?: any;
}

export interface Item {
  item_uid: string;
  product: Product;
  ordered: Ordered[];
  filled: any[];
  checked_at?: any;
  checked: boolean;
  canceled_at?: any;
  canceled: boolean;
  updated: boolean;
  cancel_reason?: any;
}

export interface Ordered {
  batch_uid: string;
  item_uid: string;
  quantity: string | number;
  unit?: any;
  batch_type: string;
  batch_id?: number;
  canceled_at?: any;
  canceled: boolean;
  product: Product;
  in_store_quantity_unit: string;
  prescription_details?: PrescriptionDetails | null;
  in_store_quantity_value: number;
  prescription_no?: number | null;
  biotrack_traceability_id?: string;
}
export interface PreOrderScannResponse {
  batch_uid: string;
  on_sale: PBSummary[];
  quantities: {
    AVAILABLE: number;
    RESERVED: number;
  };
  sku: string;
  in_this_location_since: string;
}

export interface PreOrderScann {
  batch_uid: string;
  sku: string;
  in_this_location_since: string;
  available_quantity: number;
}

export interface ProductBatch {
  batch_uid: string;
  biotrack_traceability_id: string;
  sku: string;
  in_store_quantity_unit: string;
  available_quantity: number;
  secondary_id?: string;
}

export interface OnSaleBatch extends Batch {
  biotrack_traceability_id: string;
  on_sale: PBSummary[];
}
export interface PreOrderPendingFilter {
  online_pending: boolean;
  call_in_pending: boolean;
  in_store_pending: boolean;
}

export interface PreOrderFilledFilter {
  online_filled: boolean;
  call_in_filled: boolean;
  in_store_filled: boolean;
}

export type PreOrderFilters = PreOrderPendingFilter | PreOrderFilledFilter;

export const preOrderPendingFilterDefault: PreOrderPendingFilter = {
  online_pending: true,
  call_in_pending: true,
  in_store_pending: true
};

export const preOrderFilledFilterDefault: PreOrderFilledFilter = {
  online_filled: true,
  call_in_filled: true,
  in_store_filled: true
};
