import {
  BatchInfo,
  OrderFulfillmentDetailsNew,
  OrderFulfillmentItemStatus,
  OrderFulfillmentStatus
} from "@/interfaces/orderFulfillment";
import { RetailSettings } from "@/interfaces/retailSettings";
import { TranslateResult } from "vue-i18n";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./orderFulfillmentPrint.template.vue";

@Component({
  mixins: [Template]
})
export default class OrderFulfillmentPrintComponent extends Vue {
  @Prop({ required: true })
  public status!: OrderFulfillmentStatus;
  @Prop({ required: true })
  public details!: OrderFulfillmentDetailsNew;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  public title: TranslateResult = "";
  public orderFulfillmentStatus = OrderFulfillmentStatus;

  public async created() {
    this.title =
      this.status === this.orderFulfillmentStatus.ACCEPTED
        ? this.$t("order_fulfillment.filled_saved_order")
        : this.$t("order_fulfillment.pick_list");
    this.$nextTick(() => this.$emit("readyToPrint"));
  }

  public isUpdated(item: BatchInfo) {
    return item && item.status === OrderFulfillmentItemStatus.UPDATED;
  }
}
