import { pusherEvents } from "@/enums/pusherEvents";
import {
  OrderFulfillmentPending,
  OrderFulfillmentStatus,
  PreOrderPendingFilter
} from "@/interfaces/orderFulfillment";
import { RetailSettings } from "@/interfaces/retailSettings";
import { EventBus } from "@/internal";
import { BasicTableHeaders } from "@/metadata/orderFulfillment";
import { messagesService } from "@/services/messages.service";
import { orderFulfillmentService } from "@/services/orderFulfillment.service";
import { productService } from "@/services/product.service";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import {
  TableComponent,
  TableHeader,
  TablePagination,
  TablePaginationDefault
} from "helix-vue-components";
import pickBy from "lodash/pickBy";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { TableOrderFulfillmentComponent } from "../tableOrderFulfillment/tableOrderFulfillment.component";
import Template from "./PreOrderPending.template.vue";
@Component({
  mixins: [Template],
  components: {
    TableOrderFulfillmentComponent,
    TableComponent
  },
  inject: ["$changes"]
})
export default class PreOrderPendingComponent extends Vue {
  @Prop({ required: true }) public data!: {};
  @Prop({ required: true }) public filters!: PreOrderPendingFilter;
  @Prop({ default: "" }) public search!: string;
  @Prop({ default: false }) public onDisplay!: boolean;
  @Prop({ default: null }) public pharmacistSelected!: number | null;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  public headers = BasicTableHeaders;
  public orders: OrderFulfillmentPending[] = [];
  public pagination: TablePagination = { ...TablePaginationDefault };
  public loading = true;
  public statusOrders: { [key: string]: any } = OrderFulfillmentStatus;

  @Watch("filters")
  @Watch("search")
  public onFiltersChange() {
    this.pagination = {
      ...TablePaginationDefault,
      itemsPerPage: this.pagination.itemsPerPage
    };
    this.loadOrders();
  }

  public filter(header: TableHeader) {
    const h = header;

    const sort = `${!h.descending ? "-" : ""}${h.value || ""}`;
    this.loadOrders(sort);
  }
  /**
   * Changes pagination : Responsible for switching to another page
   * @param pagination
   */
  public changePagination(pagination: TablePagination) {
    this.pagination = pagination;
    this.loadOrders();
  }

  public onBarcodeScanned(barcode: string) {
    if (this.$refs.detailsTable) {
      (this.$refs
        .detailsTable as TableOrderFulfillmentComponent).onBarcodeScanned(
        barcode
      );
    } else {
      messagesService.showMessage(
        "fas fa-exclamation-circle",
        this.$t("order_fulfillment.itemNotSelected").toString(),
        "error"
      );
    }
  }

  /**
   * On Change Pharmacist On Duty, To assign Pharmacist in Prescription Detail calling api
   */
  public async changeOrderPrescriptionDetailPharmacist(
    pharmacistId: number | null
  ) {
    if (this.orders.length) {
      const orderIds = this.orders.map(item => {
        return String(item.uid);
      });
      await orderFulfillmentService.assginPharmacistOnDuty(
        orderIds,
        pharmacistId
      );
    }
  }

  protected created() {
    // @ts-ignore
    this.$barcodeScanner.init(this.onBarcodeScanned);
  }

  protected detroyed() {
    // @ts-ignore
    this.$barcodeScanner.destroy();
  }

  /**
   * mounted : The mounted a native function from vuejs,
   * documentation: https://vuejs.org/v2/guide/instance.html
   */
  protected mounted() {
    this.$changes.watch(pusherEvents.orderFulfillment, this.loadOrders);
    EventBus.$on("loadOrdersPending", this.loadOrders);
    this.loadOrders();
    EventBus.$on("onChangePharmacistOnDuty", (pharmacistId: number | null) => {
      this.changeOrderPrescriptionDetailPharmacist(pharmacistId);
    });
  }

  /**
   * Loads orders : Responsible for loading the list of pending orders
   */
  private async loadOrders(sort?: string) {
    this.loading = true;
    const params = {
      status: this.statusOrders.PENDING.toLowerCase(),
      pagination: this.pagination,
      sort,
      search: this.search
    };

    this.orders = (await orderFulfillmentService.getOrders(
      params,
      pickBy(this.filters)
    )).map(order => {
      const dateFromHour = order.pick_up_time_from
        ? fnsFormatDate(
            new Date(order.pick_up_time_from),
            FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME
          )
        : "";

      const dateToHour = order.pick_up_time_to
        ? fnsFormatDate(new Date(order.pick_up_time_to), FNS_DATE_FORMATS.LT)
        : "";

      return {
        ...order,
        name: order.customer.name,
        customer: {
          ...order.customer,
          avatar:
            order.customer.avatar === "no-image" ? null : order.customer.avatar
        },
        pick_up_date_range:
          dateFromHour && dateToHour ? `${dateFromHour} - ${dateToHour}` : ""
      };
    });

    this.pagination = await orderFulfillmentService.getPagination(true);
    this.$changes.watch(pusherEvents.orderFulfillment, this.loadOrders);
    this.$emit("inboxTotals", {
      quantity: this.pagination.totalItems,
      inbox: "pending"
    });
    this.loading = false;
  }
}
