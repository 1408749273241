import AlertComponent from "@/components/sharedComponents/alert/alert.component";
import {
  OrderFulfillmentDetails,
  OrderFulfillmentPending
} from "@/interfaces/orderFulfillment";
import { orderFulfillmentService } from "@/services/orderFulfillment.service";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./CancelOrder.template.vue";
/**
 * Component
 */
@Component({
  mixins: [Template]
})
export class CancelOrderComponent extends Vue {
  @Prop({ required: true })
  public order!: OrderFulfillmentDetails;

  @Prop({ default: false })
  public readOnly!: boolean;

  @Prop({ required: true })
  public item!: OrderFulfillmentPending;

  public reason: string | null = "";

  /**
   * Updates : responsible for cancelling the pre-order
   */
  public async update() {
    if (!(await this.$validator.validateAll())) {
      return;
    }

    const response = await orderFulfillmentService.setStatusOrderFulfillment(
      this.order.uid,
      "cancel",
      { cancel_reason: this.reason }
    );

    if (!response) {
      await this.$modals
        .load(
          AlertComponent,
          {
            size: "fit"
          },
          {
            msgModal: {
              body:
                this.item.status === "PENDING"
                  ? this.$t(
                      "order_fulfillment.alert_update_loaded_pending"
                    ).toString()
                  : this.$t(
                      "order_fulfillment.alert_update_loaded_filled"
                    ).toString(),
              captionButton: "Accept"
            }
          }
        )
        .then(captionButton => {
          setTimeout(() => {
            window.location.reload();
          }, 2);
        });
    }

    this.$emit("resolve");
  }

  /**
   * Closes cancel pre-order modal component
   */
  public close() {
    this.$emit("reject");
  }

  protected mounted() {
    this.reason = this.order.cancel_reason;
  }
}
