import { Customer } from "@/interfaces/customer";
import {
  BatchInfo,
  EditOrderReserved,
  OnSaleBatch,
  OrderedFilled,
  ProductBatch
} from "@/interfaces/orderFulfillment";
import { RetailSettings } from "@/interfaces/retailSettings";
import { messagesService } from "@/services/messages.service";
import { orderFulfillmentService } from "@/services/orderFulfillment.service";
import { retailSettingsService } from "@/services/retailSettings.service";
import { sumObjArrayBy } from "@/utils/math.utils";
import cloneDeep from "lodash/cloneDeep";
import differenceBy from "lodash/differenceBy";
import remove from "lodash/remove";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./EditOrderItem.template.vue";

/**
 * Component
 */
@Component({
  mixins: [Template]
})
export class EditOrderItemComponent extends Vue {
  @Prop({ required: true })
  public item!: BatchInfo;
  @Prop({ default: null })
  public customer!: Customer | null;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public batchRows: OrderedFilled[] = [];
  public isValid: boolean = false;
  public selectableBatches: ProductBatch[] = [];
  public loadingBatches = false;
  public updatingBatches = false;
  public biotrackTrace!: boolean;
  private productBatches: ProductBatch[] = [];
  private reservedBatches: EditOrderReserved = {};
  /**
   * function to check if the quantities sume is valid
   * @param value
   * @returns boolean or string validation
   */
  public maxValueAllowed(value: number): boolean {
    let totalMax = 0;
    let totalRows = 0;
    if (value > 0 && this.item.ordered && this.item.ordered.length) {
      totalMax = sumObjArrayBy(this.item.ordered, it => +it.quantity);
      totalRows = sumObjArrayBy(this.batchRows, it => +it.quantity);
    }
    return totalRows <= totalMax;
  }

  /**
   * Removes row
   * @param i number
   */
  public removeRow(i: number) {
    this.batchRows.splice(i, 1);
    this.setSelectableBatches();
  }

  public get batchesRow() {
    for (const data of this.batchRows) {
      remove(data.productBatches!, items => {
        return items.available_quantity === 0;
      });
    }
    if (this.biotrackTrace && this.batchRows[0]!.biotrack_traceability_id) {
      this.batchRows[0].batchSelected!.batch_uid = this.batchRows[0].batchSelected!.biotrack_traceability_id;
    }
    return this.batchRows;
  }
  /**
   * Cancels edit order item component
   */
  public cancel() {
    this.$emit("reject");
  }

  /**
   * Updates edit order item component, responsible for updating new data and already updated data
   */
  public async update() {
    this.updatingBatches = true;
    this.isValid = await this.$validator.validateAll();

    if (this.isValid) {
      const newQuantity = this.batchRows.reduce(
        (acc, batch) => +acc + +batch.quantity,
        0
      );
      const totalMax =
        (this.item.ordered &&
          this.item.ordered.reduce(
            (acu, batch) => +acu + +batch.quantity,
            0
          )) ||
        0;
      if (newQuantity <= totalMax) {
        try {
          const updateBatch = this.batchRows.map(batchUpdated => {
            const batch = this.productBatches.filter(
              elem => elem.batch_uid === batchUpdated.batchSelected!.batch_uid
            );
            return {
              sku: batch[0]!.sku,
              quantity: Number(batchUpdated.quantity)
            };
          });
          const result = await orderFulfillmentService.updateOrderPending(
            updateBatch,
            this.item.item_uid
          );
          if (result) {
            this.$emit("resolve");
          }
        } catch (e) {
          messagesService.showMessage(
            "fal fa-exclamation-triangle",
            "order_fulfillment.bad_batch",
            "error"
          );
        }
      } else {
        messagesService.showMessage(
          "fal fa-exclamation-triangle",
          "order_fulfillment.error_quantity",
          "error"
        );
      }
    }
    this.updatingBatches = false;
  }

  /**
   * Adds batch: responsible for adding a new batch
   */
  public addBatch() {
    this.batchRows = [
      ...this.batchRows,
      {
        batch_uid: "",
        quantity: 0,
        in_store_quantity_unit: this.batchRows[this.batchRows.length - 1]
          .in_store_quantity_unit,
        in_store_quantity_value: 0,
        productBatches: cloneDeep(this.selectableBatches),
        batchSelected: null
      }
    ];
    this.setSelectableBatches();
  }

  /**
   * Sets selectable batches
   */
  public setSelectableBatches() {
    const filtered = differenceBy(
      this.productBatches || [],
      this.batchRows,
      "batch_uid"
    );
    this.selectableBatches = filtered;
  }

  /**
   * Updates the values for the suffix and max value por quantity input
   * @param value $event
   * @param selected BatchRow
   */
  public onSelectingBatch(data: {
    value?: ProductBatch | null;
    selected: OrderedFilled;
  }) {
    let batchProduct;
    if (data.value) {
      data.selected.batch_uid = data.value.batch_uid;
      batchProduct = data.value;
    } else {
      batchProduct = this.productBatches.find(
        productBatch => productBatch.batch_uid === data.selected.batch_uid
      );
    }
    const batchRowIndex = this.batchRows.findIndex(
      batch => batch.batch_uid === data.selected.batch_uid
    );
    if (batchProduct && batchRowIndex !== -1) {
      this.batchRows[batchRowIndex].in_store_quantity_unit =
        batchProduct.in_store_quantity_unit;
      this.batchRows[batchRowIndex].in_store_quantity_value =
        batchProduct.available_quantity;
      this.batchRows[batchRowIndex].batchSelected = batchProduct;
    }
    this.setSelectableBatches();
  }

  /**
   * Gets disable add : responsible for disabling the add batch id button
   */
  public get disableAdd() {
    let totalMax = 0;
    let totalRows = 0;
    if (this.item.ordered && this.item.ordered.length) {
      totalMax = sumObjArrayBy(this.item.ordered, it => +it.quantity);
      totalRows = sumObjArrayBy(this.batchRows, it => +it.quantity);
    }
    return !this.selectableBatches.length || totalRows >= totalMax;
  }

  /**
   * Gets disable update : responsible for disabling the UPDATE button
   */
  public get disableUpdate() {
    let totalMax = 0;
    let totalRows = 0;
    if (this.item.ordered && this.item.ordered.length) {
      totalMax = sumObjArrayBy(this.item.ordered, it => +it.quantity);
      totalRows = sumObjArrayBy(this.batchRows, it => +it.quantity);
    }
    return totalRows > totalMax || this.updatingBatches;
  }

  /**
   * Method to validate every quantity change against the total required
   * @param value: number
   */
  public onQuantityChange(value: number) {
    if (this.maxValueAllowed(value)) {
      this.$nextTick(() => this.$validator.validateAll());
    }
  }

  protected getReservedQuantity(batchUid: string): number {
    return this.reservedBatches[batchUid]
      ? Number(this.reservedBatches[batchUid])
      : 0;
  }

  /**
   * mounted : The mounted a native function from vuejs, responsible for filling the data to edit order item
   * documentation: https://vuejs.org/v2/guide/instance.html
   */
  protected async mounted() {
    if (this.hasBioTrackTraceIntegrations && this.bioTrackTraceEnabled) {
      this.biotrackTrace = true;
    }
    const res = await retailSettingsService.get();
    this.$store.state.AuthModule.retailSettings = res;
    const { filled, ordered } = this.item;
    if (this.item.product.sku) {
      this.loadingBatches = true;
      const response = await orderFulfillmentService.getProductBatches(
        this.customer!.customer_id!,
        this.item.product.sku
      );
      if (response && response.length) {
        this.productBatches = (response[0].batches as OnSaleBatch[]).map(
          batch => ({
            batch_uid: batch.batch_uid,
            biotrack_traceability_id: batch.biotrack_traceability_id,
            sku: batch.sku,
            in_store_quantity_unit: batch.in_store_quantity_unit,
            secondary_id:
              this.retailSettings.stock_identification_type_in_pos ===
              "SECONDARY_ID"
                ? batch.tracking_id
                : this.retailSettings.stock_identification_type_in_pos ===
                  "NDC_NUMBER"
                ? batch.ndc_number
                : "--",
            available_quantity: sumObjArrayBy(
              batch.on_sale,
              onSale => +onSale.quantity_value
            )
          })
        );
      }
    }

    if (filled && !filled.length && ordered && ordered.length) {
      this.batchRows.push(...this.formatQuantities(ordered));
    } else if (filled) {
      this.batchRows.push(...this.formatQuantities(filled));
    }

    if (this.biotrackTrace) {
      this.productBatches.map(prodBatch => {
        this.batchRows.map(batch => {
          batch.productBatches!.map(prod => {
            if (
              prod.batch_uid === prodBatch.batch_uid &&
              prodBatch.biotrack_traceability_id
            ) {
              prod.batch_uid = prodBatch.biotrack_traceability_id;
            }
          });
        });
      });
    }
    if (filled!.length) {
      filled!.forEach(filledElement => {
        this.onSelectingBatch({ selected: filledElement });
      });
    } else {
      ordered!.forEach(orderedElement => {
        this.onSelectingBatch({ selected: orderedElement });
      });
    }

    // Here we go to the productBatches array to get the unit
    // and the stock qty to show them in the dropdown-box
    // Since that info is NOT comming with the pre-order items request
    this.batchRows.forEach(element => {
      const finded = this.productBatches.find(
        el => el.batch_uid === element.batch_uid
      );
      element.secondary_id = finded ? finded.secondary_id : "";
      element.in_store_quantity_value = finded ? finded.available_quantity : 0;
      this.reservedBatches[element.batch_uid] = element.quantity;
    });

    this.setSelectableBatches();
    this.loadingBatches = false;
  }

  protected created() {
    this.$validator.extend("maxValueAllowed", {
      getMessage: (field: string) =>
        "Please, enter a smaller " + field.toLowerCase(),
      validate: this.maxValueAllowed
    });
  }

  protected formatQuantities(items: OrderedFilled[]): OrderedFilled[] {
    const batchesList = cloneDeep(this.productBatches);
    return cloneDeep(items).map((item, index) => {
      if (item.in_store_quantity_unit === "u") {
        item.quantity = parseInt(String(item.quantity), 10);
      }
      if (index > 0) {
        const batchIndex = batchesList.findIndex(
          batch => batch.batch_uid === items[index - 1].batch_uid
        );
        if (batchIndex !== -1) {
          batchesList.splice(batchIndex, 1);
        }
      }
      item.productBatches = cloneDeep(batchesList);
      item.batchSelected = null;
      return item;
    });
  }
}
