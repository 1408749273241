import { OrderFulfillmentDetails } from "@/interfaces/orderFulfillment";
import { TableAction } from "helix-vue-components";

class OrderFulfillmentActionService {
  public getRowActions(
    onShow: (order: OrderFulfillmentDetails) => void
  ): TableAction[] {
    return [
      {
        icon: "fas fa-eye",
        id: "btn_info_preorder",
        action: (order: OrderFulfillmentDetails) => onShow(order),
        tooltip: (order: OrderFulfillmentDetails) =>
          order.cancel_reason ? order.cancel_reason : ""
      }
    ];
  }
}

export const orderFulfillmentActionService = new OrderFulfillmentActionService();
